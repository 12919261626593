import { React, useState } from "react";
import Div from "../Div";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../firebase";
import Badge from "react-bootstrap/Badge";

export default function Newsletter({ title, subtitle, placeholder }) {
  const [input, setInput] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const inputHandler = (e) => {
    setError(false);
    setInput(e.target.value);
  };

  const usersCollectionRef = collection(db, "email-subscribers");
  const addSubscriber = async (e) => {
    e.preventDefault();
    console.log(input);

    if (!input.trim()) {
      setError(true);
      setSuccess(false);
      return;
    }
    await addDoc(usersCollectionRef, {
      email: input,
      subscribed: true,
      time: new Date(),
    }).catch((err) => {
      console.log(err);
      setSuccess(false);
    });

    setSuccess(true);
  };

  return (
    <>
      {title && <h2 className="cs-widget_title">{title}</h2>}
      <Div className="cs-newsletter cs-style1">
        <form action="#" className="cs-newsletter_form">
          <input
            type="email"
            className="cs-newsletter_input"
            placeholder={placeholder}
            onChange={inputHandler}
          />
          <button onClick={addSubscriber} className="cs-newsletter_btn">
            <span>Send</span>
          </button>
          <div
            className="cs-newsletter_text"
            style={{ display: success ? "" : "none" }}
          >
            <Badge bg="success"> Welcome! Email has been added.</Badge>
          </div>
          <div
            className="cs-newsletter_text"
            style={{ display: error ? "" : "none" }}
          >
            <Badge bg="danger"> You must add an email address</Badge>
          </div>
        </form>
        <Div className="cs-newsletter_text">{subtitle}</Div>
      </Div>
    </>
  );
}
