import React, { useState } from "react";
import { PopupModal } from "react-calendly";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";

export default class CalendarButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  render() {
    return (
      <div>
        <Link
          className={
            this.props.variant
              ? `cs-text_btn ${this.props.variant}`
              : "cs-text_btn"
          }
          onClick={() => this.setState({ isOpen: true })}
        >
          <>
            <span>{this.props.btnText}</span>
            {this.props.icon ? this.props.icon : <Icon icon="bi:arrow-right" />}
          </>
        </Link>
        <PopupModal
          url="https://calendly.com/kybernaut-creative/30min"
          pageSettings={this.props.pageSettings}
          utm={this.props.utm}
          prefill={this.props.prefill}
          onModalClose={() => this.setState({ isOpen: false })}
          open={this.state.isOpen}
          rootElement={document.getElementById("root")}
        />
      </div>
    );
  }
}
