import React, { useEffect, useState } from "react";
import Cta from "../Cta";
import Div from "../Div";
import Hero5 from "../Hero/Hero5";
import Spacing from "../Spacing";
import VideoModal from "../VideoModal";
import PostSlider from "../Slider/PostSlider";
import SectionHeading from "../SectionHeading";
import ServiceList from "../ServiceList";
import ServicesPage from "./ServicesPage";
import Card from "../Card";

export default function MarketingAgencyHome() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const heroSocialLinks = [
    {
      name: "YouTube",
      links: "https://www.youtube.com/",
    },
    {
      name: "Instagram",
      links: "https://www.instagram.com/",
    },
    {
      name: "TikTok",
      links: "https://www.tiktok.com/",
    },
  ];

  return (
    <>
      {/* Start Hero Section */}
      <Hero5
        title="Results Driven<br/> Social Agency"
        subtitle="A creative social agency that crafts digital strategies and engaging content to elevate your brand's presence."
        btnText="Reach Out"
        scrollDownId="#service"
        socialLinksHeading="Follow Us"
        heroSocialLinks={heroSocialLinks}
        bgImageUrl="/images/astronaut_space_riding_bmx.jpg"
      />

      {/* End Hero Section */}
      {/* Start Video Block Section */}
      <Div className="cs-video_block_1_wrap">
        <Div className="container">
          <VideoModal
            videoSrc="https://www.youtube.com/watch?v=VcaAVWtP48A"
            bgUrl="/images/video_bg_2.jpeg"
          />
        </Div>
      </Div>
      {/* End Video Block Section */}
      {/* Start Services Section */}
      {/* <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="What We Do"
          subtitle="Services"
          variant="cs-style1 text-center"
        />
        <Spacing lg="70" md="45" />
        <ServiceList />
      </Div> */}
      <Spacing lg="150" md="80" />
      <Div className="cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="Top-Tier Solutions We Provide"
                subtitle="What We Do"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-8">
              <Div className="row">
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Short Form Video"
                    link="/service/ui-ux-design"
                    src="/images/short-form-bg.png"
                    alt="Short Form Video"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Long Form Video"
                    src="/images/long-form-bg.png"
                    alt="Long Form Video"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="SEO & Data Analytics"
                    src="/images/analytics-bg.png"
                    alt="SEO & Data Analytics"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Website & Hosting"
                    src="/images/service_4.jpeg"
                    alt="Website & Hosting"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      {/* End Services Section */}
      {/* <Spacing lg="150" md="80" />
      <Div className="cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="Explore Recent Posts"
                subtitle="Blog"
                btnText="View More"
                btnLink="/blog"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-7 offset-xl-1">
              <Div className="cs-half_of_full_width">
                <PostSlider />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div> */}
      {/* End Blog Section */}
      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Partner with us - <br /> let's build something out of this world."
          btnText="Reach Out"
          bgSrc="/images/header-background.jpg"
        />
      </Div>
      {/* End CTA Section */}
    </>
  );
}
