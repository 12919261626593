import React, { useState, onClick, useEffect } from "react";
import parse from "html-react-parser";
import "./hero.scss";
import Button from "../Button";
import Div from "../Div";
import VerticalLinks from "../VerticalLinks";
import { PopupWidget } from "react-calendly";
import CalendarButton from "../Button/calendarButton";

export default function Hero({
  title,
  subtitle,
  btnText,
  scrollDownId,
  socialLinksHeading,
  heroSocialLinks,
  bgImageUrl,
}) {
  return (
    <Div
      className="cs-hero cs-style1 cs-bg cs-fixed_bg cs-shape_wrap_1"
      style={{ backgroundColor: "#090F21;" }}
    >
      <Div className="cs-shape_1" />
      <Div className="cs-shape_1" />
      <Div className="cs-shape_1" />
      <Div className="container">
        <Div className="cs-hero_text">
          <div class="row">
            <div class="col-lg-7">
              <h1 className="cs-hero_title">{parse(title)}</h1>
            </div>
            <div class="col-lg-5">
              <img src="/images/astro-bike.svg" alt="Astro Bike Man" />
            </div>
          </div>

          <Div className="cs-hero_info">
            <Div>
              <Div className="cs-hero_subtitle">{subtitle}</Div>

              <CalendarButton btnText={btnText} />
            </Div>
          </Div>
        </Div>
      </Div>
      <VerticalLinks data={heroSocialLinks} title={socialLinksHeading} />
      <a href={scrollDownId} className="cs-down_btn">
        .
      </a>
    </Div>
  );
}
